import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import Button from './components/Button';
import Logo from './assets/mst.png';
import ArtworkRedeem from './assets/artwok-redeem.png';
import './App.css';

const App = () => {
  const [coupon, setCoupon] = useState({
    value: '',
    mask: '9999-9999-9999-9999'
  });

  const { value } = coupon;

  const onChange = event => {
    const val = event.target.value;
    const newState = {
      mask: '',
      value: val
    };
    if (/[1-9A-Za-z]/.test(val)) {
      newState.mask = '';
    }
    setCoupon(newState);
  };

  const redirectToApp = e => {
    e.preventDefault();
    return window.location.replace(
      `${process.env.REACT_APP_BASE_URL}/redeem-coupon?coupon=${value.replace(
        /\s/g,
        ''
      )}`
    );
  };

  return (
    <div className='App'>
      <img src={Logo} className='App-logo' alt='MST' />
      <div className='Wrapper'>
        <div className='Wrapper-box'>
          <h3 className='Heading'>Redeem Coupon</h3>
          <img src={ArtworkRedeem} alt='not found' className='Redeem-image' />
          <p className='Secondary-text'>
            Redeem your gift card or coupon by entering your code below.
          </p>
          <form onSubmit={redirectToApp}>
            <InputMask
              className='input-mask form-input form-input__group text-sm'
              mask='* * * *    * * * *    * * * *   * * * *'
              onChange={onChange}
              alwaysShowMask
              autoFocus
            />
            <Button
              customClasses='button button--gradient-primary button--rounded button--lg'
              type='submit'
              disabled={value?.indexOf('_') > -1}
            >
              Redeem
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default App;
